<template>
<div>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a
          @click="back"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate">Transaksi Info</span>
        </span>
        <div class="text-nowrap order-md-last">
          <a class="btn px-0 d-inline-block">
            <i class="fa fa-home text-main"></i>
          </a>
        </div>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header" v-if="state.finish">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col pb-5 with-fixed-sidebar">
        <div
          class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
          :style="'background-image: url('+state.image+');'"
        >
          <div class="container text-center">
            <p class="h5 my-2 text-white">{{ state.data.pesanan.pelanggan.prefix }} {{ state.data.pesanan.pelanggan.name }}</p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-6">
            <p class="mb-1 small text-muted">PO Grup</p>
            <p class="mb-0">{{ state.data.pesanan.po.name }}</p>
          </div>
          <!-- .col -->
          <div class="col-6 text-right text-md-left">
            <p class="mb-1 small text-muted">Blok/Nomor</p>
            <p class="mb-0">{{ state.data.pesanan.pelanggan.blok_no }}</p>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mt-3">
          <div class="col-6">
            <p class="mb-1 small text-muted">Jumlah Pesanan</p>
            <p class="mb-0">{{ state.jumlah_pesanan }} pesanan, {{ state.jumlah }} pcs</p>
          </div>
          <!-- .col -->
          <div class="col-6 text-right text-md-left">
            <p class="mb-1 small text-muted">Harga</p>
            <p class="mb-0 currency">Rp. {{ changeMoney(state.total) }}</p>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mt-3 pt-3 border-top">
          <div class="col-4 text-center">
            <p class="mb-1 small">Invoice</p>
            <form>
              <div class="custom-control custom-checkbox mt-1 ml-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="invoice"
                  name="invoice"
                  :checked="state.isInvoice"
                  disabled
                />
                <label class="custom-control-label" for="customCheck1"></label>
              </div>
            </form>
          </div>
          <!-- .col -->
          <div class="col-4 text-center">
            <p class="mb-1 small">Pembayaran</p>
            <form>
              <div class="custom-control custom-checkbox mt-1 ml-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="pembayaran"
                  name="pembayaran"
                  :checked="state.isPembayaran"
                />
                <label class="custom-control-label" for="customCheck2" @click="changeStatus('pembayaran')"></label>
              </div>
            </form>
          </div>
          <!-- .col -->
          <div class="col-4 text-center">
            <p class="mb-1 small">Metode Bayar</p>
            <p id="metode-terpilih" class="mb-0">{{ state.data.pembayaran }}</p>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <hr class="no-side-margin" />

        <div class="row mt-3">
          <div class="col-12">
            <form>
              <div class="form-group">
                <label for="teks">Teks Invoice:</label>
                <textarea
                  class="form-control form-control-sm"
                  rows="5"
                  id="teks"
                  name="teks"
                  placeholder="Teks invoice..."
                  v-model="state.invoice_text"
                ></textarea
                >
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Isian ini wajib diisi.</div>
              </div>
            </form>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mb-2">
          <div class="col-12 text-center">
            <button id="shareBtn" class="btn btn-main btn-sm text-uppercase" @click="kirimInvoice">
              <i class="fa fa-check-square-o mr-4"></i> Kirim Invoice
            </button>

            <button class="btn btn-main btn-sm text-uppercase ml-4" @click="UpdateHarga">
              Update Harga
            </button>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mb-2">
          <div class="col-12 px-0">
            <ul class="list-group rounded-0 mt-3">
              <!-- Hanya muncul bila belum ada bukti pembayaran -->
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  border-left-0 border-right-0
                  p-0
                "
              >
                <router-link
                  :to="'/jual/transaksi/upload/'+state.data.id"
                  class="py-2 pl-3 text-uppercase stretched-link"
                  >Upload Bukti Pembayaran</router-link
                >
                <i class="fa fa-caret-right mr-3"></i>
              </li>
              <!-- Hanya muncul bila ada bukti pembayaran -->
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  border-left-0 border-right-0
                  p-0
                "
                v-if="state.photoBukti !== null"
              >
                <a
                  href=""
                  class="py-2 pl-3 text-uppercase stretched-link"
                  data-toggle="modal"
                  data-target="#imgModal"
                  >Lihat Bukti Pembayaran</a
                >
                <i class="fa fa-caret-right mr-3"></i>
              </li>
            </ul>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row my-3">
          <div class="col-12">
            <Notif />
            <Form
              @submit="updateData"
              :validation-schema="schema"
              v-slot="{ errors }"
              method="post"
              >
              <div class="form-group mb-2">
                <label for="catatan">Catatan:</label>
                <Field type="hidden" name="catatan" v-model="state.form.catatan" />
                <textarea
                  class="form-control form-control-sm"
                  rows="2"
                  id="catatan"
                  name="catatan"
                  :class="{ 'is-invalid': errors.catatan }"
                  placeholder="Masukkan catatan..."
                  v-model="state.form.catatan"
                ></textarea>
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">{{ errors.catatan }}</div>
              </div>
              <button
                class="btn btn-outline-main btn-sm text-uppercase"
              >
                <i class="fas fa-save mr-1"></i> Simpan
              </button>
              <button
                class="btn btn-outline-secondary btn-sm text-uppercase"
                disabled
              >
                <i class="fa fa-times mr-1"></i> Hapus
              </button>
            </Form>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
  <div class="modal fade" id="imgModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body text-center">
          <img :src="state.url + state.photoBukti" :alt="state.photoBukti" class="img-fluid shadow-sm mx-auto">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div> <!-- END: #imgModal -->
  <!-- modal -->
</div>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useRoute } from 'vue-router';
import imageBaner from "../../../assets/img/ca1.jpg";
import { Form, Field } from 'vee-validate';
import Notif from '../../../helpers/Notif.vue';
import * as Yup from "yup";
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import Share from '../../../components/social_media/Share';
import moment from 'moment';

export default {
  setup() {
    const route = useRoute();
    const $swal = useVueSweetAlert2();
    const Toast = $swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    });
    const state = reactive({
      data: {},
      finish: false,
      jumlah_pesanan: 0,
      jumlah: 0,
      total: 0,
      image: imageBaner,
      invoice_text: '',
      photoBukti: null,
      url: process.env.VUE_APP_API_URL,
      form: {
        catatan: '',
      },
      //status
      isInvoice: false,
      isPembayaran: false,
    })
    const loadData = async () => {
      const id = route.params.id;
      await axios.get("/api/seller/transaksi/"+id).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          state.finish = true;
          state.jumlah_pesanan = state.data.pesanan.detail.length;
          state.jumlah = 0;
          state.total  = 0;
          state.data.pesanan.detail.map((item) => {
            state.jumlah  += Number(item.jumlah);
            state.total   += Number(item.total);
          });
          state.photoBukti = state.data.photo;
          const prefix = state.data.pesanan.pelanggan.prefix == null? '': state.data.pesanan.pelanggan.prefix
          const name = prefix +" "+ state.data.pesanan.pelanggan.name;
          let toko = "Nama Toko"
          let footer = ""
          let isDetail = false;
          let textDetail = "";
          if(res.data.toko !== null){
            const tk = res.data.toko
            toko = tk.nama;
            footer = tk.footer;
            isDetail = tk.detail_invoice;
            if(isDetail){
              textDetail = "\n--"
              state.data.pesanan.detail.map((detail) => {
                textDetail += "\n>"+detail.produk.name
                       +"\nRp."+changeMoney(Number(detail.harga) + Number(detail.margin)) +" x "+detail.jumlah+"pcs = "+changeMoney((Number(detail.harga) + Number(detail.margin))*detail.jumlah)
              });
            }
          }
          let text = "INVOICE #"+state.data.invoice_no
                      +"\n"+toko
                      +"\n"+state.data.pesanan.po.name
                      +"\n"+moment(new Date()).format('DD/MM/YYYY')
                      +"\n--"
                      +"\nTo: *"+name+"*"
                      +"\n"+state.data.pesanan.pelanggan.group.name +", "+state.data.pesanan.pelanggan.blok_no+" "+state.data.pesanan.pelanggan.alamat
                      +textDetail
                      +"\n--"
                      +"\n"+state.jumlah_pesanan+" pesanan, "+state.jumlah+" pcs"
                      +"\nOngkir: Rp "+changeMoney(state.data.pesanan.ongkir)
                      +"\n*Total : Rp "+changeMoney(Number(state.data.total) + Number(state.data.pesanan.ongkir))+"*"
                      +"\n--"
                      +"\n"+footer+')';

          state.invoice_text = text;
          state.form.catatan = state.data.catatan === null? '' : state.data.catatan;
          //status
          state.isInvoice = state.data.pesanan.invoice;
          state.isPembayaran = state.data.pesanan.pembayaran;
        }
      });
    };
    onMounted(() => {
      loadData();
    });
    const changeMoney = (dt) => {
        let cur = Math.ceil(Number(dt));
        let mo = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let ret = mo.split(".")[0]; 
        return ret; 
    }
    //start validate
    const schema = Yup.object().shape({
      catatan: Yup.string(),
    });

    const updateData = () => {
      const id = route.params.id;
      axios.put('/api/seller/transaksi/'+id, state.form).then((res) => {
        if(res.data.success){
          $swal.fire({
            icon: "success",
            title: "Selamat",
            text: "Catatan Berhasil Ditambahkan!",
          });
          loadData();
        }
      })
    }
    const kirimInvoice = () => {
      //change status 
      const form = {
        status: 'invoice',
        value: true
      }
      axios.post('/api/seller/pesanan/setStatus/'+state.data.pesanan_id, form).then((res) => {
        if(res.data.success){
          loadData();
        }
      });
      //share wa
      let dt = {
        title: '',
        description: state.invoice_text,
        url: process.env.VUE_APP_HOME_URL,
      }
      Share.run('whatsapp', dt);
    }
    const changeStatus = (dt) => {
        $swal
          .fire({
            text: "Apakah Anda Yakin?",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
          })
          .then((result) => {
            if (result.isConfirmed) {
              state.status = dt;
              let val = !state.isPembayaran;
              let form = {
                status: dt,
                value: val
              }
              axios.post('/api/seller/pesanan/setStatus/'+state.data.pesanan_id, form).then((res) => {
                if(res.data.success){
                  loadData();
                  Toast.fire({
                    icon: 'success',
                    title: "Status Berhasil Dirubah!"
                  });
                }
              })
            }
        });
    }
    const back = () => {
      window.history.back();
    }
    const UpdateHarga = () => {
      const id = route.params.id;
      axios.get('/api/seller/transaksi/updateHarga/'+id).then((res) => {
        if(res.data.success){
          loadData();
          $swal.fire({
            icon: "success",
            title: "Info",
            text: "Harga Berhasil di Update!",
          });
        }
      })
    }
    return {
      state,
      changeMoney,
      schema,
      updateData,
      kirimInvoice,
      changeStatus,
      back,
      UpdateHarga
    }
  },
  components: {
    Form,
    Field,
    Notif,
  }
}
</script>